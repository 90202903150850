import React from 'react';

import CityLinkImage from '../../images/works/city-link/IPhones';
import RocketIcon from '../../../images/icons/rocket.svg';
import LightBulbIcon from '../../../images/icons/light-bulb.svg';

import ProjectBox from './ProjectBox';

const list = [
  {
    icon: RocketIcon,
    title: 'Prototyp Entwicklung',
  },
  {
    icon: LightBulbIcon,
    title: 'Vision & Konzeption',
  },
];

const CityLinkProjectBox = () => (

  <ProjectBox
    imageComponent={<div className="md:py-12 md:px-4"><CityLinkImage /></div>}
    title="City Link"
    subTitle="Smart City Plattform für items"
    list={list}
    link="/works/city-link"
  />
);

export default CityLinkProjectBox;

import React from 'react';
import ContactButton from '../misc/ContactButton';

export default () => (
  <div className="flex flex-row flex-wrap -mx-4 p-4 sm:mx-0 bg-brand">
    <div className="flex flex-col w-full md:items-center text-white p-5">
      <h2>Haben wir dein Interesse geweckt?</h2>
      <br />
      <span>Kontaktiere uns und wir planen gemeinsam dein individuelles Projekt.</span>
      <ContactButton label="Kontakt" className="button-outlined border-white" />
    </div>
  </div>
);

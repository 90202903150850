import React from 'react';
import Layout from '../components/Layout';
import HeaderSection from '../components/sections/HeaderSection';
import SEO from '../components/SEO';
import AreYouInterested from '../components/sections/AreYouInterested';
import ToolOverview from '../components/pages/career/ToolOverview';
import EdyoucatedProjectBox from '../components/sections/project-boxes/Edyoucated';
import CityLinkProjectBox from '../components/sections/project-boxes/CityLink';
import WohinDuWillstProjectBox from '../components/sections/project-boxes/WohinDuWillst';

const WohinDuWillst = () => (
  <Layout>
    <SEO title="Unsere Projekte" keywords={[]} />
    <HeaderSection>
      <>
        <h1>
          Unsere Projekte
        </h1>
        <div className="flex flex-row w-full mt-8">
          <p className="w-full md:w-2/3">
            Unser Ziel ist es nachhaltige, moderne und stabile Software zu entwickeln, die ihre Nutzer begeistert.
            Von diesem Anspruch haben seitdem unsere Kunden und die Nutzer unserer Lösungen profitiert.
          </p>
        </div>
      </>
    </HeaderSection>
    <div className="flex flex-col">
      <WohinDuWillstProjectBox />
      <CityLinkProjectBox />
    </div>
    <AreYouInterested />
    <div className="flex flex-row flex-wrap justify-center">
      <div className="flex flex-col w-full md:items-center md:p-5 max-w-md">
        <h2 className="font-bold md:text-center">Technologien</h2>
        <p className="mt-2 md:mt-12 text-left">
          Wir haben den Anspruch für jedes Projekt die passenden Technologien auszuwählen. Dabei versuchen wir stets neueste Technologien zu nutzen.
        </p>
      </div>
      <ToolOverview />
    </div>
  </Layout>
);

export default WohinDuWillst;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const ProjectBox = ({
  imageComponent,
  title,
  subTitle,
  list,
  link,
}) => (
  <Link to={link} className="flex flex-row flex-wrap w-full box mt-4 text-black animate-icon-in">
    <div className="flex flex-col w-full md:pr-8 md:w-1/2">
      <div className="max-w-xs md:max-w-full">
        {imageComponent}
      </div>
    </div>
    <div className="flex flex-col w-full md:pl-8 md:w-1/2 mt-2 justify-center">
      <h3>{title}</h3>
      <span>{subTitle}</span>
      <div className="mt-6">
        {list.map(el => (
          <div className="flex flex-row items-center mt-2">
            <img src={el.icon} className="w-8 h-8" alt="Icon" />
            <span className="ml-2">{el.title}</span>
          </div>
        ))}
      </div>
      <div className="mt-6">
        <div className="text-brand">
          zum Projekt
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>
    </div>
  </Link>
);

ProjectBox.propTypes = {
  imageComponent: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  link: PropTypes.string.isRequired,
};

export default ProjectBox;

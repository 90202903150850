import React from 'react';

import WohinDuWillstImage from '../../images/works/wohin-du-willst/Header';
import RocketIcon from '../../../images/icons/rocket.svg';
import LightBulbIcon from '../../../images/icons/light-bulb.svg';

import ProjectBox from './ProjectBox';

const list = [
  {
    icon: RocketIcon,
    title: 'Backend Development',
  },
  {
    icon: LightBulbIcon,
    title: 'Produktmanagement',
  },
];

const WohinDuWillstProjectBox = () => (

  <ProjectBox
    imageComponent={<WohinDuWillstImage />}
    title="Wohin·Du·Willst"
    subTitle="Mobilitätsapp der DB Regio Bus"
    list={list}
    link="/works/wohin-du-willst"
  />
);

export default WohinDuWillstProjectBox;
